// import Image_14 from "../../assets/img/Image 14.png";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loader from "../UI/Loader";
import noImage from "../../assets/img/no-image (1).png";
import SideAds from "../Third-PartyAds/SideAds";
import MainAd from "../Third-PartyAds/MainAd";
import TrendingPosts from "../SideNews/TrendingPost";
import { Constants } from "../../Middleware/Constant";
import { DataContext } from "../Context/SidebarContext";
import SideAd from "../Third-PartyAds/Advertise";
import editIcon from "../../assets/img/pencil_2606509 (2).png";
// const user = localStorage.getItem("ATuserData");
// const userData = JSON.parse(user);
// console.log(userData);

function Home_News() {
  const {
    data: adsData,
    homeNews,
    isLoading2,
    error2,
    breakingNews: breaking,
    isloading1,
    error1,
    user,
  } = useContext(DataContext);

  useEffect(() => {}, [user]);
  // destructure the data...
  const flashPosts = homeNews?.data?.flash_news?.posts;
  const trendingPost = homeNews?.data?.trending_news;

  return (
    <section className="lg:pb-24 md:pb-8 pb-0 news-blog bg-blur">
      <div className="container sm:px-7 px-4 mx-auto ">
        <div className="grid grid-cols-12 gap-5">
          <div className="lg:col-span-9 md:col-span-8 col-span-12">
            {/* {isloading1 === true && <Loader />} */}
            <div className="grid grid-cols-12 sm:gap-5 gap-3">
              {adsData?.data?.home_page_2_ads && (
                <MainAd adsData={adsData?.data?.home_page_2_ads} />
              )}

              <div className="col-span-12">
                <div
                  className={`relative rounded-[10px] breaking-img ${
                    user ? "group/item edit-hover" : ""
                  }`}
                >
                  {!breaking?.data && !error1 ? (
                    <>
                      <Skeleton height={200}></Skeleton>
                    </>
                  ) : error1 ? (
                    <h1 className="Absolute bg-red text-white h-[100px] flex justify-center items-center rounded-lg px-5">
                      {error1}
                    </h1>
                  ) : (
                    <>
                      <Link
                        to={`/${breaking?.data?.post_slug}`}
                        aria-label={`${breaking?.data?.post_slug}`}
                        rel="preload"
                        fetchpriority="high"
                        className="block edit-img-hover relative"
                      >
                        <div
                          className={`relative rounded-[10px]`}
                          style={{
                            backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)),
                               url(${breaking?.data?.post_image})  `,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            height: "18rem",
                          }}
                        ></div>
                      </Link>
                      <div className="absolute sm:bottom-12 sm:left-11 bottom-6 left-5">
                        <Link
                          to={`/category/${breaking?.data?.cat?.cate_slug}`}
                        >
                          <span className="bg-red text-white text-[10px] px-3 py-1 text-center font-openSans uppercase font-semibold">
                            {breaking?.data?.cat
                              ? breaking.data.cat.cate_title
                              : ""}
                          </span>
                        </Link>
                        <Link
                          to={`${breaking?.data?.post_slug}`}
                          aria-label={`${breaking?.data?.post_slug}`}
                        >
                          <h1 className="text-[20px] leading-6 mt-[13px] pr-2 text-white max-w-[350px] font-black wow fadeInDown hover:underline">
                            {breaking?.data?.post_title}
                          </h1>
                        </Link>
                      </div>
                      {user && (
                        <a
                          href={`${breaking?.data?.post_admin_link}`}
                          target="_blank"
                        >
                          <div className="absolute top-0 right-0 mr-2 mt-2 cursor-pointer font-openSans bg-[#fff] text-sm px-4 py-2 font-bold z-[2] invisible group-hover/item:visible hover:bg-blue-400 rounded-lg shadow-md shadow-black">
                            Edit
                            <img
                              src={editIcon}
                              className="inline-block h-3 w-3 ml-2 mb-1"
                              alt="edit"
                            />
                          </div>
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="news-blog-inner-first hidden md:block col-span-12 md:col-span-5 lg:col-span-4">
                <SideAd htmlData={adsData?.data?.home_page_1_ads} />

                <TrendingPosts
                  trendingPost={trendingPost}
                  isLoading2={isLoading2}
                  error2={error2}
                  user={user}
                />
              </div>

              {/* for preserv layout */}
              <div className="news-blog-inner-second col-span-12 md:col-span-7 lg:col-span-8 bg-[#E4E3E1] p-4 rounded-md">
                <div className="grid lg:grid-cols-2 news-blog-inner-1">
                  <div className="block lg:pr-5 lg:pl-0 lg:pb-0 pb-3 lg:border-r border-b border-greyb">
                    {!error2 && flashPosts?.length >= 0 ? (
                      <div
                        className={`${
                          user ? "group/item edit-hover relative" : ""
                        }`}
                      >
                        <Link
                          to={`/${flashPosts[0]?.post_slug}`}
                          aria-label={`${flashPosts[0]?.post_slug}`}
                          className="edit-img-hover block relative "
                        >
                          <img
                            src={
                              flashPosts[0]?.post_image
                                ? flashPosts[0]?.post_image
                                : noImage
                            }
                            alt="flash news"
                            className="w-full md:max-h-36 object-cover sm:max-h-72 max-h-64 rounded-[10px]"
                            // style={{ height: "9rem", width: "100%" }}
                          />
                        </Link>
                        {user && (
                          <a
                            href={`${flashPosts[0]?.post_admin_link}`}
                            target="_blank"
                          >
                            <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                              <img
                                src={editIcon}
                                className="inline-block h-3 w-3  mb-1"
                                alt="edit"
                              />
                            </div>
                          </a>
                        )}
                        <Link to={`/category/${flashPosts[0]?.cat?.cate_slug}`}>
                          <span className="text-red text-[10px] sm:pl-0 pl-2 font-openSans font-semibold uppercase inline-block my-2">
                            {flashPosts[0]?.cat?.cate_title}
                          </span>
                        </Link>
                        <Link
                          to={`/${flashPosts[0]?.post_slug}`}
                          aria-label={`${flashPosts[0]?.post_slug}`}
                        >
                          <p className="font-bold text-sm wow lg:mb-5 sm:pl-0 pl-2 h-10 truncate-text  fadeInDown hover:text-red">
                            {flashPosts[0]?.post_title}
                          </p>
                        </Link>
                      </div>
                    ) : error2 ? (
                      <img
                        src={noImage}
                        className="lg:pt-0 pt-5 w-full md:max-h-36 sm:max-h-72 rounded-[10px]"
                        alt="placeholder"
                      ></img>
                    ) : (
                      <Skeleton height={150}></Skeleton>
                    )}
                  </div>

                  <div className="block lg:pl-5 border-b border-greyb">
                    {!error2 && flashPosts?.length >= 1 ? (
                      <div
                        className={`${
                          user ? "group/item edit-hover relative" : ""
                        }`}
                      >
                        <Link
                          to={`/${flashPosts[1]?.post_slug}`}
                          aria-label={`${flashPosts[1]?.post_slug}`}
                          className="edit-img-hover block relative "
                        >
                          <img
                            src={
                              flashPosts[1]?.post_image
                                ? flashPosts[1]?.post_image
                                : noImage
                            }
                            alt="flash news"
                            className="lg:pt-0 lg:mt-0 mt-5 w-full md:max-h-36 object-cover max-h-64 sm:max-h-72 rounded-[10px]"
                            // style={{ height: "9rem", width: "100%" }}
                          />
                        </Link>
                        {user && (
                          <a
                            href={`${flashPosts[1]?.post_admin_link}`}
                            target="_blank"
                          >
                            <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                              <img
                                src={editIcon}
                                className="inline-block h-3 w-3  mb-1"
                                alt="edit"
                              />
                            </div>
                          </a>
                        )}
                        <Link to={`/category/${flashPosts[1]?.cat?.cate_slug}`}>
                          <span className="text-red text-[10px] sm:pl-0 pl-2 font-openSans font-semibold uppercase inline-block my-2">
                            {flashPosts[1]?.cat?.cate_title}
                          </span>
                        </Link>
                        <Link
                          to={`/${flashPosts[1]?.post_slug}`}
                          aria-label={`${flashPosts[1]?.post_slug}`}
                        >
                          <p className="font-bold text-sm md:mb-5 sm:pl-0 pl-2 mb-2 lg:mb-5 h-10 wow truncate-text fadeInDown hover:text-red">
                            {flashPosts[1]?.post_title}
                          </p>
                        </Link>
                      </div>
                    ) : error2 ? (
                      <img
                        src={noImage}
                        className="lg:pt-0 pt-5 w-full md:max-h-36 sm:max-h-72 rounded-[10px]"
                        alt="placeholder"
                      ></img>
                    ) : (
                      <Skeleton height={150}></Skeleton>
                    )}
                  </div>
                  <div className="block lg:pr-5 lg:pl-0 lg:border-r lg:border-b lg:border-greyb">
                    {!error2 && flashPosts?.length >= 2 ? (
                      <div
                        className={`${
                          user ? "group/item edit-hover relative" : ""
                        }`}
                      >
                        <Link
                          to={`/${flashPosts[2]?.post_slug}`}
                          aria-label={`${flashPosts[2]?.post_slug}`}
                          className="edit-img-hover block relative "
                        >
                          <img
                            src={
                              flashPosts[2]?.post_image
                                ? flashPosts[2]?.post_image
                                : noImage
                            }
                            alt="flash news"
                            className="mt-4 w-full md:max-h-36 max-h-64 sm:max-h-72 object-cover rounded-[10px]"
                          />
                        </Link>
                        {user && (
                          <a
                            href={`${flashPosts[2]?.post_admin_link}`}
                            target="_blank"
                          >
                            <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                              <img
                                src={editIcon}
                                className="inline-block h-3 w-3  mb-1"
                                alt="edit"
                              />
                            </div>
                          </a>
                        )}
                        <Link to={`/category/${flashPosts[2]?.cat?.cate_slug}`}>
                          <span className="text-red text-[10px] sm:pl-0 uppercase pl-2 font-openSans font-semibold inline-block my-2">
                            {flashPosts[2]?.cat?.cate_title}
                          </span>
                        </Link>
                        <Link
                          to={`/${flashPosts[2]?.post_slug}`}
                          aria-label={`${flashPosts[2]?.post_slug}`}
                        >
                          <p className="font-bold text-sm pb-5 lg:mb-5 sm:pl-0 pl-2 h-10 truncate-text wow fadeInDown hover:text-red">
                            {flashPosts[2]?.post_title}
                          </p>
                        </Link>
                      </div>
                    ) : error2 ? (
                      <img
                        src={noImage}
                        className="lg:pt-0 pt-5 w-full md:max-h-36 object-cover sm:max-h-72 rounded-[10px]"
                        alt="placeholder"
                      ></img>
                    ) : (
                      <Skeleton height={150}></Skeleton>
                    )}
                  </div>
                  <div className=" hidden lg:block ">
                    <div className="block lg:pl-5 border-b border-greyb">
                      {!error2 && flashPosts?.length >= 3 ? (
                        <div
                          className={`${
                            user ? "group/item edit-hover relative" : ""
                          }`}
                        >
                          <Link
                            to={`/${flashPosts[3]?.post_slug}`}
                            aria-label={`${flashPosts[3]?.post_slug}`}
                            className="edit-img-hover block relative "
                          >
                            <img
                              src={
                                flashPosts[3]?.post_image
                                  ? flashPosts[3]?.post_image
                                  : noImage
                              }
                              alt="flash news"
                              className="mt-4 w-full md:max-h-36 object-cover sm:max-h-72 rounded-[10px]"
                              // style={{ height: "9rem", width: "100%" }}
                            />
                          </Link>
                          {user && (
                            <a
                              href={`${flashPosts[3]?.post_admin_link}`}
                              target="_blank"
                            >
                              <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                <img
                                  src={editIcon}
                                  className="inline-block h-3 w-3  mb-1"
                                  alt="edit"
                                />
                              </div>
                            </a>
                          )}
                          <Link
                            to={`/category/${flashPosts[3]?.cat?.cate_slug}`}
                          >
                            <span className="text-red text-[10px] font-openSans sm:pl-0 pl-2 font-semibold inline-block my-2">
                              {flashPosts[3]?.cat?.cate_title.toUpperCase()}
                            </span>
                          </Link>
                          <Link
                            to={`/${flashPosts[3]?.post_slug}`}
                            aria-label={`${flashPosts[3]?.post_slug}`}
                          >
                            <p className="font-bold text-sm pb-5 h-10 lg:mb-5 sm:pl-0 pl-2 truncate-text wow fadeInDown hover:text-red">
                              {flashPosts[3]?.post_title}
                            </p>
                          </Link>
                        </div>
                      ) : error2 ? (
                        <img
                          src={noImage}
                          className="lg:pt-0 pt-5 w-full md:max-h-36 object-cover sm:max-h-72 rounded-[10px]"
                          alt="placeholder"
                        ></img>
                      ) : (
                        <Skeleton height={150}></Skeleton>
                      )}
                    </div>
                  </div>
                  <div className=" hidden lg:block lg:border-r border-greyb">
                    <div className="block lg:pr-5  ">
                      {!error2 && flashPosts?.length >= 4 ? (
                        <div
                          className={`${
                            user ? "group/item edit-hover relative" : ""
                          }`}
                        >
                          <Link
                            to={`/${flashPosts[4]?.post_slug}`}
                            aria-label={`${flashPosts[4]?.post_slug}`}
                            className="edit-img-hover block relative "
                          >
                            <img
                              src={
                                flashPosts[4]?.post_image
                                  ? flashPosts[4]?.post_image
                                  : noImage
                              }
                              alt="flash news"
                              className="mt-4 w-full md:max-h-36 sm:max-h-72 object-cover rounded-[10px]"
                              // style={{ height: "9rem", width: "100%" }}
                            />
                          </Link>
                          {user && (
                            <a
                              href={`${flashPosts[4]?.post_admin_link}`}
                              target="_blank"
                            >
                              <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                <img
                                  src={editIcon}
                                  className="inline-block h-3 w-3 mb-1"
                                  alt="edit"
                                />
                              </div>
                            </a>
                          )}
                          <Link
                            to={`/category/${flashPosts[4]?.cat?.cate_slug}`}
                          >
                            <span className="text-red text-[10px] sm:pl-0 pl-2 uppercase font-openSans font-semibold inline-block my-2">
                              {flashPosts[4]?.cat?.cate_title}
                            </span>
                          </Link>
                          <Link
                            to={`/${flashPosts[4]?.post_slug}`}
                            aria-label={`${flashPosts[4]?.post_slug}`}
                          >
                            <p className="font-bold text-sm md:pb-0 sm:pl-0 pl-2 lg:mb-5 h-10 truncate-text wow fadeInDown hover:text-red">
                              {flashPosts[4]?.post_title}
                            </p>
                          </Link>
                        </div>
                      ) : error2 ? (
                        <img
                          src={noImage}
                          className="lg:pt-0 pt-5 w-full md:max-h-36 sm:max-h-72 rounded-[10px]"
                          alt="placeholder"
                        ></img>
                      ) : (
                        <Skeleton height={150}></Skeleton>
                      )}
                    </div>
                  </div>

                  <div className=" hidden lg:block">
                    <div className="block lg:pl-5 ">
                      {!error2 && flashPosts?.length >= 5 ? (
                        <div
                          className={`${
                            user ? "group/item edit-hover relative" : ""
                          }`}
                        >
                          <Link
                            to={`/${flashPosts[5]?.post_slug}`}
                            aria-label={`${flashPosts[5]?.post_slug}`}
                            className="edit-img-hover block relative "
                          >
                            <img
                              src={
                                flashPosts[5]?.post_image
                                  ? flashPosts[5]?.post_image
                                  : noImage
                              }
                              alt="flash news"
                              className="mt-4 w-full md:max-h-36 object-cover sm:max-h-72 rounded-[10px]"
                              // style={{ height: "9rem", width: "100%" }}
                            />{" "}
                          </Link>
                          {user && (
                            <a
                              href={`${flashPosts[5]?.post_admin_link}`}
                              target="_blank"
                            >
                              <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                <img
                                  src={editIcon}
                                  className="inline-block h-3 w-3  mb-1"
                                  alt="edit"
                                />
                              </div>
                            </a>
                          )}
                          <Link
                            to={`/category/${flashPosts[5]?.cat?.cate_slug}`}
                          >
                            <span className="text-red text-[10px] sm:pl-0 pl-2 font-openSans font-semibold inline-block my-2">
                              {flashPosts[5]?.cat?.cate_title.toUpperCase()}
                            </span>
                          </Link>
                          <Link
                            to={`/${flashPosts[5]?.post_slug}`}
                            aria-label={`${flashPosts[5]?.post_slug}`}
                          >
                            <p className="font-bold text-sm md:pb-0 pb-5 sm:pl-0 pl-2 h-10 truncate-text wow fadeInDown hover:text-red">
                              {flashPosts[5]?.post_title}
                            </p>
                          </Link>
                        </div>
                      ) : error2 ? (
                        <img
                          src={noImage}
                          className="lg:pt-0 pt-5 w-full md:max-h-36 sm:max-h-72 rounded-[10px]"
                          alt="placeholder"
                        ></img>
                      ) : (
                        <Skeleton height={150}></Skeleton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-3 md:col-span-4 col-span-12">
            <SideAds />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home_News;
