// import Asian_Trader_leaderboard from "../../assets/img/01-11-24-Asian-Trader-leaderboard-728x90-1.png";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import noImage from "../../assets/img/no-image (1).png";
import Loader from "../UI/Loader";
import useFetchNews from "../../Middleware/useFetchNews";
import CurrentIssue from "../Third-PartyAds/CurrentIssue";
import MainAd from "../Third-PartyAds/MainAd";
import { useContext, useEffect } from "react";
import { DataContext } from "../Context/SidebarContext";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

const getClassNames = (index) => {
  switch (index) {
    case 0:
      return "lg:pr-5 sm:pr-[10px] px-[5px] md:border-r  border-b border-greyb";
    case 1:
      return "lg:pl-5 sm:pl-[10px] px-[5px] border-b  border-greyb";
    case 2:
      return "lg:pr-5 sm:pr-[10px] px-[5px] border-b sm:border-b-0 md:border-r border-greyb";
    default:
      return "lg:pl-5 sm:pl-[10px] px-[5px]  border-greyb";
  }
};

const Home_ProductNews = () => {
  const categorySlug = "product-news";
  const numberOfPosts = 5;
  const layout = "four_small_column_one_large_column";

  const {
    data: productNews,
    loading,
    error,
  } = useFetchNews(categorySlug, numberOfPosts, layout);

  const { data: adsData, user } = useContext(DataContext);
  const ads = adsData?.data;

  useEffect(() => {}, [user]);

  const productData = productNews?.data;

  return (
    <section className="lg:pb-24 md:pb-8 pb-8 product" id="products">
      {productData?.posts.length > 0 ? (
        <div className="container mx-auto sm:px-7 px-4 relative">
          <div className="inner-box2 flex items-center md:pb-[50px] sm:pb-[25px] pb-[15px] sm:pl-[36px] pl-[50px] text-[20px]  text-red font-bold">
            <h2 className="uppercase text-nowrap text-[14px] sm:text-[20px] ">
              {productData?.category_name}
            </h2>
            <div className="border-y-2 h-[13px] lg:w-[75%] md:w-[65%] w-[55%] ml-auto"></div>
          </div>
          <div className="grid grid-cols-12 gap-5">
            <div className="lg:col-span-9 md:col-span-12 col-span-12">
              <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
                <div className="md:col-span-2 lg:col-span-2  col-span-4 bg-[#E4E3E1] lg:p-5 p-[10px] md:p-4 rounded-md">
                  <div className="grid md:grid-cols-2 grid-cols-1">
                    {!error ? (
                      productData?.posts?.slice(0, 4)?.map((item, index) => (
                        <div
                          key={index}
                          className={`block ${getClassNames(index)}`}
                        >
                          <div>
                            <div
                              className={`${
                                user ? "group/item edit-hover " : ""
                              }flex-1 relative`}
                            >
                              <Link
                                to={`/${item?.post_slug}`}
                                aria-label={`${item.post_slug}`}
                                className="relative edit-img-hover"
                              >
                                <img
                                  src={item.post_image}
                                  alt=""
                                  className={`${
                                    index === 0
                                      ? "w-full lg:max-h-36 md:max-h-32 sm:max-h-80 max-h-60 rounded-[10px] sm:mt-0 mt-2 object-cover"
                                      : index === 1
                                      ? "w-full lg:max-h-36 md:max-h-32 sm:max-h-80 max-h-60 rounded-[10px] sm:mt-0 mt-5 object-cover"
                                      : "w-full lg:max-h-36 md:max-h-32 sm:max-h-80 max-h-60 rounded-[10px] mt-5 object-cover"
                                  }`}
                                  style={{ height: "14rem", width: "100%" }}
                                />{" "}
                              </Link>
                              {user && (
                                <a href={item.post_admin_link} target="_blank">
                                  <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                    <img
                                      src={editIcon}
                                      className="inline-block h-3 w-3  mb-1"
                                      alt="edit"
                                    />
                                  </div>
                                </a>
                              )}
                            </div>
                            <div className="flex-1">
                              <Link to={`category/${item?.cat?.cate_slug}`}>
                                <span className="text-red text-[10px] inline-block uppercase sm:pl-0 pl-2 my-2 font-openSans  font-semibold">
                                  {item?.cat?.cate_title}
                                </span>
                              </Link>
                              <Link
                                to={`/${item?.post_slug}`}
                                aria-label={`${item.post_slug}`}
                              >
                                <p className="font-bold md:pb-0 mb-3 lg:min-h-8 min-h-8 sm:pl-0 pl-2 truncate-text text-[14px] wow fadeInDown hover:text-red">
                                  {item?.post_title}
                                </p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : error ? (
                      <div></div>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
                <div className="md:col-span-1 lg:col-span-1 col-span-4 bg-[#E4E3E1] lg:p-5 p-[10px] rounded-md">
                  {!error ? (
                    <div
                      className={`${
                        user ? "group/item edit-hover " : ""
                      }lg:block pt-1 px-[5px] relative `}
                    >
                      <Link
                        to={`/${productData?.posts[4]?.post_slug}`}
                        aria-label={`${productData?.posts[4]?.post_slug}`}
                        className="inline-block relative edit-img-hover lg:h-96 md:h-80 sm:h-56 h-52 w-full"
                      >
                        <img
                          src={productData?.posts[4]?.post_image}
                          alt=""
                          className="w-full object-cover h-full   lg:max-h-96 lg:max-w-60 md:max-w-full lg:min-w-40 sm:max-h-80 max-h-60 rounded-[10px]"
                          // style={{ height: "25rem", width: "25rem" }}
                        />
                      </Link>
                      {user && (
                        <a
                          href={productData?.posts[4]?.post_admin_link}
                          target="_blank"
                        >
                          <div className="absolute top-0 right-0 mr-2 mt-2 hover:bg-blue-400 invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] z-[2] text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                            <img
                              src={editIcon}
                              className="inline-block h-3 w-3  mb-1"
                              alt="edit"
                            />
                          </div>
                        </a>
                      )}
                      <div className="md:ml-5 lg:ml-0">
                        <Link
                          to={`category/${productData?.posts[4]?.cat?.cate_slug}`}
                        >
                          <span className="text-[10px] text-red sm:pl-0 pl-2 font-openSans uppercase font-semibold md:mt-[20px]  inline-block my-2">
                            {productData?.posts[4]?.cat?.cate_title}
                          </span>
                        </Link>
                        <Link
                          to={`/${productData?.posts[4]?.post_slug}`}
                          aria-label={`${productData?.posts[4]?.post_slug}`}
                        >
                          <p className="text-[14px] sm:pl-0 pl-2 min-h-8 font-bold wow fadeInDown hover:text-red">
                            {productData?.posts[4]?.post_title}
                          </p>
                        </Link>
                      </div>
                    </div>
                  ) : error ? (
                    <div></div>
                  ) : (
                    <Skeleton count={1} height={200} />
                  )}
                </div>
                {ads?.home_page_2_ads && (
                  <div className="md:col-span-3 col-span-4 bg-[#E8E8E8] rounded-md">
                    <MainAd adsData={ads?.home_page_2_ads} />
                  </div>
                )}
              </div>
            </div>
            <div className="lg:col-span-3 md:col-span-4 lg:block hidden col-span-12 bg-[#231E1E] p-5 rounded-[10px]">
              <CurrentIssue />
            </div>
          </div>
        </div>
      ) : (
        <>
          <Loader />
        </>
      )}
    </section>
  );
};

export default Home_ProductNews;
