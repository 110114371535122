import { useContext } from "react";
// import Mask_Group_3 from "../../assets/img/Mask Group 3.png";
// import Image_9 from "../../assets/img/Image 9.png";
// import Image_10 from "../../assets/img/Image 10.png";
import { DataContext } from "../Context/SidebarContext";
import SideAd from "./Advertise";

export default function SideAds() {
  const { data } = useContext(DataContext);
  const ads = data?.data;

  return (
    <>
      <div className="">
        <SideAd htmlData={ads?.mpu_1_ads} />
      </div>
      <div className="mb-5">
        <SideAd htmlData={ads?.mpu_2_ads} />
      </div>
      <div className="mb-5">
        <SideAd htmlData={ads?.mpu_3_ads} />
      </div>
      <div className="mb-5">
        <SideAd htmlData={ads?.mpu_4_ads} />
      </div>
    </>
  );
}
