import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
// import noImage from "../../assets/img/no-image (1).png";
import Loader from "../UI/Loader";
import useFetchNews from "../../Middleware/useFetchNews";
import { useContext } from "react";
import { DataContext } from "../Context/SidebarContext";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

export default function Home_Interviews() {
  const categorySlug = "interviews-2";
  const numberOfPosts = 7;
  const layout = "three_large_column_four_small_column";

  const { user } = useContext(DataContext);

  const {
    data: interviews,
    loading,
    error,
  } = useFetchNews(categorySlug, numberOfPosts, layout);

  const interviewData = interviews?.data;

  return (
    <section className="lg:pb-24 pb-8" id="inter">
      {interviewData?.posts?.length > 0 ? (
        <div className="container mx-auto sm:px-7 px-4 relative">
          <div className="inner-box2 flex items-center md:pb-[50px] sm:pb-[25px] pb-[15px] sm:pl-[36px] pl-[50px] text-[20px] text-red font-bold">
            <h2 className="uppercase text-[14px] sm:text-[20px]">
              {interviewData?.category_name}
            </h2>
            <div className="border-y-2 h-[13px] lg:w-[75%] md:w-[65%] w-[55%] ml-auto"></div>
          </div>
          {
            // loading === true ? (
            //   <Loader />
            // ) :
            error ? (
              <div></div>
            ) : (
              <div className="grid grid-cols-12 sm:gap-5 gap-3">
                <>
                  {interviewData?.posts?.slice(0, 3)?.map((item, index) => (
                    <div key={index} className="md:col-span-4  col-span-12">
                      <div className="rounded-[10px] bg-[#EEEEEE] h-full">
                        <div
                          className={`flex flex-col h-full ${
                            user ? "group/item edit-hover relative" : ""
                          }`}
                        >
                          <Link
                            to={`/${item.post_slug}`}
                            aria-label={`${item.post_slug}`}
                            className="edit-img-hover block relative "
                          >
                            <img
                              src={item.post_image}
                              alt=""
                              className="w-full md:max-h-56 md:min-h-56  lg:min-h-64 lg:max-h-64 sm:max-h-80 max-h-60  object-cover object-top rounded-[10px]"
                              // loading="lazy"
                            />
                          </Link>
                          {user && (
                            <a href={item.post_admin_link} target="_blank">
                              <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                <img
                                  src={editIcon}
                                  className="inline-block h-3 w-3  mb-1"
                                  alt="edit"
                                />
                              </div>
                            </a>
                          )}
                          <div className="sm:px-4 sm:py-4 py-2 px-6 lg:px-8">
                            <Link to={`/category/${item?.cat?.cate_slug}`}>
                              <span className="text-[10px] text-[#5E5E5E] font-openSans uppercase font-semibold">
                                {item.cat.cate_title}
                              </span>
                            </Link>
                            <Link
                              to={`/${item.post_slug}`}
                              aria-label={`${item.post_slug}`}
                            >
                              <h2 className="font-bold text-[14px] sm:mt-[14px] sm:mb-6 mt-2 mb-2 wow fadeInDown hover:text-red">
                                {item.post_title}
                              </h2>
                            </Link>
                          </div>
                          <div className="flex justify-between text-[10px] font-openSans mt-auto sm:px-4 px-6 sm:py-4 pb-8 lg:px-8">
                            <p className="">{item.post_author}</p>
                            <p className="text-gray-900 ">{item.post_date}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {interviewData?.posts?.slice(3, 7)?.map((item, index) => (
                    <div key={index} className="md:col-span-6 col-span-12">
                      <div
                        className={`rounded-[10px] bg-[#EEEEEE] flex lg:flex-row md:flex-col flex-row   lg:pb-0  h-full ${
                          user ? "group/item edit-hover relative" : ""
                        }`}
                      >
                        <div className="lg:min-w-6/12 md:max-h-56 md:min-h-52 sm:max-h-48  max-h-36 min-h-36 flex-1">
                          <Link
                            to={`/${item.post_slug}`}
                            aria-label={`${item.post_slug}`}
                            className="edit-img-hover relative "
                          >
                            <img
                              src={item.post_image}
                              alt=""
                              className="w-full h-full  object-cover object-top rounded-[10px]"
                            />
                          </Link>
                          {user && (
                            <a href={item.post_admin_link} target="_blank">
                              <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-3 py-1 font-bold rounded-lg shadow-md shadow-black">
                                <img
                                  src={editIcon}
                                  className="inline-block h-3 w-3  mb-1"
                                  alt="edit"
                                />
                              </div>
                            </a>
                          )}
                        </div>
                        <div className="sm:pt-4 pt-3 px-4  flex-1">
                          <Link to={`/category/${item?.cat?.cate_slug}`}>
                            <span className="text-[10px] text-[#5E5E5E] font-openSans uppercase font-semibold">
                              {item.cat.cate_title}
                            </span>
                          </Link>
                          <div className="lg:min-h-[78px]">
                            <Link
                              to={`/${item.post_slug}`}
                              aria-label={`${item.post_slug}`}
                            >
                              <h2 className="font-bold sm:text-sm text-xs mt-[14px] truncate-text wow fadeInDown hover:text-red">
                                {item.post_title}
                              </h2>
                            </Link>
                          </div>
                          <div className="mt-auto py-4">
                            <p className="text-[10px] font-openSans ">
                              {item.post_author}
                            </p>
                            <p className="text-[10px] text-[#5E5E5E] font-openSans ">
                              {item.post_date}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
            )
          }
        </div>
      ) : (
        <>
          <Loader />
        </>
      )}
    </section>
  );
}
