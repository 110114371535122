import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loader from "../UI/Loader";
import NewsLetter from "../Third-PartyAds/NewsLetter";
import useFetchNews from "../../Middleware/useFetchNews";
import { DataContext } from "../Context/SidebarContext";
import { useContext } from "react";
import SideAd from "../Third-PartyAds/Advertise";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

const Home_IndustryNews = () => {
  const categorySlug = "industry-news";
  const numberOfPosts = 6;
  const layout = "two_small_column_four_large_column";
  const {
    data: industryNews,
    loading,
    error,
  } = useFetchNews(categorySlug, numberOfPosts, layout);

  const { data: adsData, user } = useContext(DataContext);
  const ads = adsData?.data;

  const industryData = industryNews?.data;

  return (
    <section className="lg:pb-24 pb-8 product">
      {industryData?.posts.length > 0 ? (
        <div className="container mx-auto sm:px-7 px-4 relative">
          <div className="inner-box2 flex items-center md:pb-[50px] sm:pb-[25px] pb-[15px] sm:pl-[36px] pl-[50px] text-[20px] text-red font-bold">
            <h2 className="uppercase text-nowrap text-[14px] sm:text-[20px] ">
              {industryData?.category_name}
            </h2>
            <div className="border-y-2 h-[13px] lg:w-[75%] md:w-[65%] w-[55%] ml-auto"></div>
          </div>
          {
            // loading === true ? (
            //   <Loader />
            // ) :
            error ? (
              <div></div>
            ) : (
              <div className="grid grid-cols-12 gap-5">
                <div className="lg:col-span-9  md:col-span-8 col-span-12">
                  <div className="bg-[#DAD9D7] sm:p-5 p-3 rounded-md lg:flex gap-5">
                    <div className="flex flex-col sm:gap-5 gap-3 lg:max-w-[27rem] md:w-[100%] lg:w-[50%] ">
                      {industryData?.posts?.slice(0, 3)?.map((post, index) =>
                        index === 0 ? (
                          <div key={index} className="block">
                            <div className="rounded-[10px] bg-white h-full">
                              <div className="flex flex-col h-full">
                                <div
                                  className={`${
                                    user ? "group/item edit-hover relative" : ""
                                  }`}
                                >
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                    className="edit-img-hover block relative"
                                  >
                                    <img
                                      src={post.post_image}
                                      alt="industry news"
                                      className="md:max-h-52  sm:max-h-64 max-h-56 object-cover w-full   rounded-[10px]"
                                      // style={{ height: "13rem", width: "100%" }}
                                    />
                                  </Link>
                                  {user && (
                                    <a
                                      href={post.post_admin_link}
                                      target="_blank"
                                    >
                                      <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                        <img
                                          src={editIcon}
                                          className="inline-block h-3 w-3  mb-1"
                                          alt="edit"
                                        />
                                      </div>
                                    </a>
                                  )}
                                </div>
                                <div className="sm:p-5 p-3">
                                  <Link to={`category/${post.cat?.cate_slug}`}>
                                    <span className="text-[10px] text-[#5E5E5E] font-openSans uppercase font-semibold">
                                      {post.cat?.cate_title}
                                    </span>
                                  </Link>
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                  >
                                    {" "}
                                    <p className="text-[14px] mt-[23px] mb-[18px]  truncate-text font-bold wow fadeInDown lg:min-h-10 hover:text-red">
                                      {post.post_title}
                                    </p>
                                  </Link>
                                </div>
                                <div className="flex justify-between text-[10px] font-openSans font-semibold mt-auto pb-5 sm:px-5 px-3">
                                  <p className="">{post.post_author}</p>
                                  <p className="text-[#5E5E5E]">
                                    {post.post_date}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div key={index}>
                            <div
                              className={`rounded-[10px] bg-white flex ${
                                user ? "group/item edit-hover relative" : ""
                              }`}
                            >
                              <div className={`shrink-0`}>
                                <Link
                                  to={`/${post.post_slug}`}
                                  aria-label={`${post.post_slug}`}
                                  className="edit-img-hover block relative "
                                >
                                  <img
                                    src={post.post_image}
                                    alt="industry news"
                                    className=" md:max-h-32 md:min-h-32 max-h-32 min-h-32 sm:min-h-28  max-w-[118px] object-cover md:max-w-[168px] lg:min-w-[118px] md:min-w-[168px] min-w-[118px] lg:max-w-[118px] h-full rounded-[10px] "
                                    // style={{ width: "100%" }}
                                  />
                                </Link>
                                {user && (
                                  <a
                                    href={post.post_admin_link}
                                    target="_blank"
                                  >
                                    <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-2 py-1 font-bold rounded-lg shadow-md shadow-black">
                                      <img
                                        src={editIcon}
                                        className="inline-block h-3 w-3  mb-1"
                                        alt="edit"
                                      />
                                    </div>
                                  </a>
                                )}
                              </div>
                              <div className="sm:pl-[23px] pl-[.8rem] pr-2 sm:pt-[15px] pt-[.4rem]">
                                <Link to={`category/${post.cat.cate_slug}`}>
                                  <span className="text-[10px] text-[#5E5E5E] uppercase font-openSans font-semibold">
                                    {post.cat?.cate_title}
                                  </span>
                                </Link>
                                <Link
                                  to={`/${post.post_slug}`}
                                  aria-label={`${post.post_slug}`}
                                >
                                  <p className="font-black xl:text-sm text-xs mt-1 truncate-text mb-[5px] wow fadeInDown hover:text-red">
                                    {post.post_title}
                                  </p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    <div className="flex flex-col sm:gap-5 gap-3 mt-5 lg:mt-0 lg:max-w-[27rem] md:w-[100%] lg:w-[50%]">
                      {industryData?.posts?.slice(3, 6)?.map((post, index) =>
                        index === 0 ? (
                          <div key={index} className="block">
                            <div className="rounded-[10px] bg-white h-full">
                              <div className="flex flex-col h-full">
                                <div
                                  className={`${
                                    user ? "group/item edit-hover relative" : ""
                                  }`}
                                >
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                    className="edit-img-hover block relative "
                                  >
                                    <img
                                      src={post.post_image}
                                      alt="industry news"
                                      className="md:max-h-52 sm:max-h-64 max-h-56 object-cover w-full   rounded-[10px]"
                                    />
                                  </Link>
                                  {user && (
                                    <a
                                      href={post.post_admin_link}
                                      target="_blank"
                                    >
                                      <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                                        <img
                                          src={editIcon}
                                          className="inline-block h-3 w-3  mb-1"
                                          alt="edit"
                                        />
                                      </div>
                                    </a>
                                  )}
                                </div>
                                <div className="sm:p-5 p-3">
                                  <Link to={`category/${post.cat.cate_slug}`}>
                                    <span className="text-[10px] text-[#5E5E5E] uppercase font-openSans font-semibold">
                                      {post?.cat?.cate_title}
                                    </span>
                                  </Link>
                                  <Link
                                    to={`/${post.post_slug}`}
                                    aria-label={`${post.post_slug}`}
                                  >
                                    <p className="text-[14px] mt-[23px] mb-[18px] font-bold truncate-text wow fadeInDown min-h-10 hover:text-red">
                                      {post?.post_title}
                                    </p>
                                  </Link>
                                </div>
                                <div className="flex justify-between text-[10px] font-openSans font-semibold mt-auto pb-5 sm:px-5 px-3">
                                  <p className="">{post.post_author}</p>
                                  <p className="text-[#5E5E5E]">
                                    {post?.post_date}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className={`rounded-[10px] bg-white flex ${
                              user ? "group/item edit-hover relative" : ""
                            }`}
                          >
                            <div className="shrink-0">
                              <Link
                                to={`/${post.post_slug}`}
                                aria-label={`${post.post_slug}`}
                                className="edit-img-hover block relative "
                              >
                                <img
                                  src={post.post_image}
                                  alt="industry news"
                                  className="md:max-h-32 md:min-h-32 max-h-32 min-h-32 sm:min-h-28  max-w-[118px] object-cover lg:min-w-[118px] md:min-w-[168px] min-w-[118px] md:max-w-[168px] lg:max-w-[118px] h-full rounded-[10px]"
                                  style={{ width: "100%" }}
                                />
                              </Link>
                              {user && (
                                <a href={post.post_admin_link} target="_blank">
                                  <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-2 py-1 font-bold rounded-lg shadow-md shadow-black">
                                    <img
                                      src={editIcon}
                                      className="inline-block h-3 w-3  mb-1"
                                      alt="edit"
                                    />
                                  </div>
                                </a>
                              )}
                            </div>
                            <div className="sm:pl-[23px] pl-[.8rem] pr-2 sm:pt-[15px] pt-[.4rem]">
                              <Link to={`category/${post.cat?.cate_slug}`}>
                                <span className="text-[10px] text-[#5E5E5E] uppercase font-openSans font-semibold">
                                  {post.cat?.cate_title}
                                </span>
                              </Link>
                              <Link
                                to={`/${post.post_slug}`}
                                aria-label={`${post.post_slug}`}
                              >
                                <p className="font-black xl:text-sm text-xs truncate-text mt-1 mb-[5px] wow fadeInDown hover:text-red">
                                  {post?.post_title}
                                </p>
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-3 md:block hidden md:col-span-4 col-span-4 ">
                  <NewsLetter />
                  <div className="mt-5">
                    <SideAd htmlData={ads?.mpu_5_ads} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      ) : (
        <>
          <Loader />
        </>
      )}
    </section>
  );
};

export default Home_IndustryNews;
