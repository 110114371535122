import Home_News from "../../Component/Home/Home_News";
import Home_ProductNews from "../../Component/Home/Home_ProductNews";
import Home_IndustryNews from "../../Component/Home/Home_IndustryNews";
import Home_MeAndMy from "../../Component/Home/Home_MeAndMy";
import Home_Interviews from "../../Component/Home/Home_Interviews";
import Home_Report from "../../Component/Home/Home_Report";
// import { DataProvider } from "../../Component/Context/Sideba/Context";
// import { Helmet } from "react-helmet-async";
// import useFetchMetaTags from "../../Middleware/useFetchMetaTags";

function Home() {
  const PageSlug = "Home-page";
  // const { data: tags } = useFetchMetaTags(PageSlug);
  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <main>
        <Home_News />
        <Home_ProductNews />
        <Home_IndustryNews />
        <Home_MeAndMy />
        <Home_Interviews />
        <Home_Report />
      </main>
    </>
  );
}

export default Home;
