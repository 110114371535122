import { useState, useEffect, useCallback } from 'react';
import { Constants } from './Constant';

const useFetchNews = (categorySlug, numberOfPosts, layout) => {
    const [data, setData] = useState(() => {
        // Load persisted data from localStorage if available
        const savedData = localStorage.getItem(`${categorySlug}-${numberOfPosts}-${layout}`);
        return savedData ? JSON.parse(savedData) : null;
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // console.log("get item", localStorage.getItem(`${categorySlug}-${numberOfPosts}-${layout}`))
    // console.log(data);

    const fetchData = useCallback(async () => {
        const urlEncodedData = new URLSearchParams();
        urlEncodedData.append("cate_slug", categorySlug);
        urlEncodedData.append("number_of_posts", numberOfPosts);
        urlEncodedData.append("image_Layout", layout);
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(
                Constants.backendUrl + "home-category-news",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Basic ${Constants.token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: urlEncodedData.toString(),
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();




            // Get the current data from localStorage
            const savedData = localStorage.getItem(`${categorySlug}-${numberOfPosts}-${layout}`);
            const savedDataParsed = savedData ? JSON.parse(savedData) : null;
            // console.log(JSON.stringify(result) === JSON.stringify(savedDataParsed))

            // Compare the fetched result with saved data
            if (JSON.stringify(result) !== JSON.stringify(savedDataParsed)) {
                setData(result);
                localStorage.setItem(`${categorySlug}-${numberOfPosts}-${layout}`, JSON.stringify(result));
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [categorySlug, numberOfPosts, layout]);

    useEffect(() => {

        fetchData();

    }, [fetchData]);

    return { data, loading, error };
};

export default useFetchNews;
