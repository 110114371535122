import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import noImage from "../../assets/img/no-image (1).png";
import Loader from "../UI/Loader";
import useFetchNews from "../../Middleware/useFetchNews";
import { DataContext } from "../Context/SidebarContext";
import { useContext } from "react";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

const Home_MeAndMy = () => {
  const categorySlug = "me-and-my";
  const numberOfPosts = 5;
  const layout = "one_big_column_four_small_column";

  const { user } = useContext(DataContext);

  const {
    data: meAndMyNews,
    loading,
    error,
  } = useFetchNews(categorySlug, numberOfPosts, layout);

  const meAndMyData = meAndMyNews?.data;

  return (
    <section className="lg:pb-24 pb-8" id="memy">
      {meAndMyData?.posts?.length > 0 ? (
        <div className="container mx-auto sm:px-7 px-4 relative">
          <div className="inner-box2 flex items-center md:pb-[50px] sm:pb-[25px] pb-[15px] sm:pl-[36px] pl-[50px] text-[20px] text-red font-bold">
            <h2 className="uppercase text-nowrap text-[14px] sm:text-[20px]">
              {meAndMyData?.category_name}
            </h2>
            <div className="border-y-2 h-[13px] lg:w-[75%] w-[65%] ml-auto"></div>
          </div>

          {
            // loading === true ? (
            //   <>
            //     {" "}
            //     <Loader />
            //   </>
            // ) :
            error ? (
              <div></div>
            ) : (
              <>
                <div className="grid lg:grid-rows-3 lg:grid-flow-col lg:grid-cols-4 xl:gap-10 sm:gap-5 gap-2 bg-[#383434] xl:p-10 sm:p-5 p-2 rounded-lg ">
                  <div
                    className={`relative lg:col-span-2 lg:row-span-2  md:col-span-12 col-span-12  overflow-hidden rounded-[10px] ${
                      user ? "group/item edit-hover relative" : ""
                    } `}
                  >
                    <Link
                      to={`/${meAndMyData?.posts[0]?.post_slug}`}
                      aria-label={`${meAndMyData?.posts[0]?.post_slug}`}
                      className="edit-img-hover  relative "
                    >
                      <div
                        className="rounded-[10px] md:w-full  lg:h-full md:h-[25rem] sm:h-[23rem] h-[20rem] max-w-[100%]"
                        style={{
                          backgroundImage: `linear-gradient(to top, #000, rgba(0, 0, 0, 0)), url(${meAndMyData?.posts[0]?.post_image})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </Link>
                    {user && (
                      <a
                        href={meAndMyData?.posts[0]?.post_admin_link}
                        target="_blank"
                      >
                        <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-4 py-2 font-bold rounded-lg shadow-md shadow-black">
                          <img
                            src={editIcon}
                            className="inline-block h-3 w-3  mb-1"
                            alt="edit"
                          />
                        </div>
                      </a>
                    )}
                    <div className="sm:w-full sm:max-w-full max-w-[25rem] sm:p-5 p-2 pl-3 absolute left-0 bottom-0">
                      <Link
                        to={`/category/${meAndMyData?.posts[0]?.cat?.cate_slug}`}
                      >
                        <span className="text-white bg-red sm:px-7 px-5 py-1 text-[10px] font-openSans uppercase font-semibold">
                          {meAndMyNews?.data?.posts[0]?.cat?.cate_title}
                        </span>
                      </Link>
                      <Link
                        to={`/${meAndMyData?.posts[0]?.post_slug}`}
                        aria-label={`${meAndMyData?.posts[0]?.post_slug}`}
                      >
                        <h2
                          className="md:text-[20px] text-[17px]  text-white mt-[10px] sm:mt-[23px] sm:mb-[14px] mb-2 wow fadeInDown hover:text-red"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInDown",
                          }}
                        >
                          {meAndMyData?.posts[0]?.post_title}
                        </h2>
                      </Link>

                      <div className="flex justify-between text-[10px] text-[#fff]  font-openSans font-semibold">
                        <p className="">{meAndMyData?.posts[0]?.post_author}</p>
                        <p className="">{meAndMyData?.posts[0]?.post_date}</p>
                      </div>
                    </div>
                  </div>

                  {meAndMyData?.posts?.slice(1, 5)?.map((item, index) => (
                    <div
                      key={index}
                      className={`flex col-span-12 lg:col-span-2 md:col-span-6 p-2 md:p-2 lg:p-2 lg:flex-row md:flex-col flex-row sm:gap-5 gap-2 bg-[#000000] rounded-md sm:p-5 ${
                        user ? "group/item edit-hover relative" : ""
                      }  `}
                    >
                      <div className="shrink-0 md:w-auto   w-28 sm:w-40 md:min-h-60 lg:min-h-48 ">
                        <Link
                          to={`/${item.post_slug}`}
                          aria-label={`${item.post_slug}`}
                          className="relative edit-img-hover"
                        >
                          <img
                            src={item?.post_image}
                            alt=""
                            className="w-full h-full min-h-[7rem]   max-h-28 sm:max-h-40  sm:min-h-40 lg:max-w-52 md:min-h-60 lg:min-h-48  object-cover rounded-[10px]"
                            // style={{ height: "8rem", width: "200px" }}
                          />
                        </Link>
                        {user && (
                          <a href={item.post_admin_link} target="_blank">
                            <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-3 py-1 font-bold rounded-lg shadow-md shadow-black">
                              <img
                                src={editIcon}
                                className="inline-block h-3 w-3  mb-1"
                                alt="edit"
                              />
                            </div>
                          </a>
                        )}
                      </div>
                      <div className="lg:px-0 sm:px-5 px-1  lg:pb-0 sm:pb-3 pb-1 flex flex-col h-full flex-1">
                        <div>
                          <Link to={`/category/${item?.cat?.cate_slug}`}>
                            <span className="text-white bg-red sm:px-7 px-4 py-1 text-[10px] uppercase font-openSans font-semibold">
                              {item?.cat?.cate_title}
                            </span>
                          </Link>
                          <Link
                            to={`/${item.post_slug}`}
                            aria-label={`${item.post_slug}`}
                          >
                            <p
                              className="xl:text-[20px] lg:text-[18px] md:text-[15px] text-[12px] truncate-text  sm:pt-[18px] pt-[8px] lg:pb-0 sm:pb-[36px] mb-[10px] text-white wow fadeInDown hover:text-red"
                              style={{
                                visibility: "visible",
                                animationName: "fadeInDown",
                              }}
                            >
                              {item?.post_title}
                            </p>
                          </Link>
                        </div>
                        <div className="flex justify-between text-[10px] text-[#fff] font-openSans font-semibold mt-auto">
                          <p className="">{item.post_author}</p>
                          <p className="">{item.post_date}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
          }
        </div>
      ) : (
        <>
          {" "}
          <Loader />
        </>
      )}
    </section>
  );
};

export default Home_MeAndMy;
