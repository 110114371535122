import { Link } from "react-router-dom";
// import Skeleton from "react-loading-skeleton";
import Loader from "../UI/Loader";
import useFetchNews from "../../Middleware/useFetchNews";
import CurrentIssue from "../Third-PartyAds/CurrentIssue";
import NewsLetter from "../Third-PartyAds/NewsLetter";
import SideAd from "../Third-PartyAds/Advertise";
import { DataContext } from "../Context/SidebarContext";
import { useContext } from "react";
import editIcon from "../../assets/img/pencil_2606509 (2).png";

export default function Home_Report() {
  const categorySlug = "features";
  const numberOfPosts = 15;
  const layout = "nine_big_column_six_small_column";

  const {
    data: reports,
    loading,
    error,
  } = useFetchNews(categorySlug, numberOfPosts, layout);

  // console.log(report)
  const { data: adsData, user } = useContext(DataContext);
  const ads = adsData?.data;
  const reportData = reports?.data;
  // console.log(reportData);

  return (
    <section className="lg:pb-24 pb-8" id="rep">
      {reportData?.posts?.length > 0 ? (
        <div className="container mx-auto sm:px-7 px-4 relative">
          <div className="inner-box2 flex items-center md:pb-[50px] sm:pb-[25px] pb-[15px] sm:pl-[36px] pl-[50px] text-[20px] text-red font-bold">
            <h2 className="uppercase text-[14px] sm:text-[20px]">
              {reportData?.category_name}
            </h2>
            <div className="border-y-2 h-[13px] lg:w-[75%] w-[65%] ml-auto"></div>
          </div>

          {
            // loading === true ? (
            //   <Loader />
            // ) :
            error ? (
              <div> </div>
            ) : (
              <div className="grid grid-cols-12 gap-5">
                <div className="lg:col-span-9 md:col-span-8 col-span-12 ">
                  <div className="grid grid-cols-12 sm:gap-5 gap-3">
                    {reportData?.posts?.slice(0, 9)?.map((report, index) => (
                      <div
                        key={index}
                        className="lg:col-span-4 sm:col-span-6 col-span-12"
                      >
                        <div className="rounded-[10px] bg-[#EEEEEE] h-full">
                          <div
                            className={`${
                              user ? "group/item edit-hover relative" : ""
                            }`}
                          >
                            <Link
                              to={`/${report.post_slug}`}
                              aria-label={`${report.post_slug}`}
                              className="edit-img-hover block relative "
                            >
                              <img
                                src={report.post_image}
                                alt={report.post_slug}
                                className="w-full max-h-44 object-cover rounded-[10px]"
                                style={{ height: "11rem", width: "100%" }}
                              />
                            </Link>
                            {user && (
                              <a href={report.post_admin_link} target="_blank">
                                <div className="absolute top-0 right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-3 py-2 font-bold rounded-lg shadow-md shadow-black">
                                  <img
                                    src={editIcon}
                                    className="inline-block h-3 w-3  mb-1"
                                    alt="edit"
                                  />
                                </div>
                              </a>
                            )}
                          </div>
                          <div className="sm:px-[23px] sm:pt-[18px] sm:pb-[23px] pt-2 px-4 pb-4">
                            <Link to={`/category/${report.cat.cate_slug}`}>
                              <span className="text-[10px] text-[#5E5E5E] font-openSans font-semibold">
                                {report.cat.cate_title.toUpperCase()}
                              </span>
                            </Link>
                            <Link
                              to={`/${report.post_slug}`}
                              aria-label={`${report.post_slug}`}
                            >
                              <h2 className="sm:text-sm text-xs sm:mt-[23px] sm:mb-[18px] mt-3 mb-3 truncate-text font-black wow fadeInDown hover:text-red">
                                {report.post_title}
                              </h2>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="lg:col-span-3 md:col-span-4 col-span-12 mr-2">
                  {reportData?.posts?.slice(9, 15)?.map((feature, index) => (
                    <div
                      key={index}
                      className={`grid grid-cols-12 gap-5 pb-[21px] mb-6 ${
                        index > 4 ? "" : " border-b border-[#707070] "
                      } ${user ? "group/item edit-hover relative" : ""}`}
                    >
                      <div className="col-span-4">
                        <Link
                          to={`/${feature.post_slug}`}
                          aria-label={`${feature.post_slug}`}
                          className="edit-img-hover relative"
                        >
                          <img
                            src={feature.post_image}
                            alt={""}
                            className="w-full lg:h-full object-cover rounded-[10px]"
                            style={{ height: "5rem", width: "100%" }}
                          />
                        </Link>
                      </div>

                      <div className="col-span-8">
                        <Link to={`/category/${feature.cat.cate_slug}`}>
                          <span className="text-[10px] text-red block font-openSans uppercase font-semibold  mb-2">
                            {feature.cat.cate_title}
                          </span>
                        </Link>
                        <Link
                          to={`/${feature.post_slug}`}
                          aria-label={`${feature.post_slug}`}
                        >
                          <p className="font-bold text-xs leading-4 pt-2  wow fadeInDown hover:text-red ">
                            {feature.post_title}
                          </p>
                        </Link>
                      </div>
                      {user && (
                        <a href={feature.post_admin_link} target="_blank">
                          <div className="absolute top-[-15px] right-0 mr-2 mt-2 z-[2] invisible group-hover/item:visible cursor-pointer font-openSans bg-[#fff] hover:bg-blue-400 text-sm px-2 py-1 font-bold rounded-lg shadow-md shadow-black">
                            <img
                              src={editIcon}
                              className="inline-block h-3 w-3  mb-1"
                              alt="edit"
                            />
                          </div>
                        </a>
                      )}
                    </div>
                  ))}
                  <SideAd htmlData={ads?.mpu_6_ads} />
                </div>
              </div>
            )
          }
          <div className="md:hidden block  col-span-12 bg-[#231E1E] p-5 rounded-[10px]">
            <CurrentIssue />
          </div>
          <div className="col-span-12 md:hidden block pt-5 ">
            {/* <NewsLetter /> */}
          </div>
        </div>
      ) : (
        <>
          <Loader />
        </>
      )}
    </section>
  );
}
